<template lang="pug">
  .ofertas-validar-single
    
    .box-search
      .container
        .row
          .col-sm-12
            a(href="#" @click="goBack()") #[translate Volver]
            // translate Oferta:
            // strong {{number}}
    .info-pedido-ok(v-if="messageShow" class="fade in" :class="{'error': ['pendingvalidation'].includes(stage)}")
      div(v-if="stage === 'offermodeoffline'")
        p
          i(class="fa fa-check" aria-hidden="true")
        p
          strong {{ messageError }}
        p #[translate Puede ver el historial de ofertas en]
          router-link( :to="{ name: 'ofertasList'}" class="" ) #[translate Ofertas]
        p
          router-link( :to="{ name: config_app.url_home}" class="btn btn-action" ) #[translate Volver al inicio]
      div(v-else-if="stage === 'offervalidated'")
        p
          i(class="fa fa-check" aria-hidden="true")
        p
          strong #[translate La Oferta se ha realizado con éxito].
        p #[translate Puede ver el historial de ofertas en]
          router-link( :to="{ name: 'ofertasList'}" class="" ) #[translate Ofertas]
        p
          router-link( :to="{ name: config_app.url_home}" class="btn btn-action" ) #[translate Volver al inicio]

      div(v-else-if="stage === 'offeranulated'")
        p
          i(class="fa fa-check" aria-hidden="true")
        p
          strong #[translate La Oferta se ha anulado con éxito].
        p #[translate Puede ver el historial de ofertas en]
          router-link( :to="{ name: 'ofertasList'}" class="" ) #[translate Ofertas]
        p
          router-link( :to="{ name: config_app.url_home}" class="btn btn-action" ) #[translate Volver al inicio]
      div(v-else-if="stage === 'pendingvalidation'")
        // cuando son ofertas y supera el límite
        p
          img(:src="img.alerta" width="70" height="70" class="tw-m-auto")
        p #[translate Esta oferta supera el límite de consumo de este centro].
          br
          | #[La oferta pasa a estar pendiente de validación].
        p #[translate Puede verlo en]
          router-link( :to="{ name: 'validarOfertas'}" class="" ) #[translate Validar ofertas]
        p
          router-link( :to="{ name: config_app.url_home}" class="btn btn-action" ) #[translate Volver al inicio]


    div(v-if="!messageShow")

      .box-1
        .container
          .row
            div(v-if="messageError && messageError !== 1")
              p(class="alert alert-warning fade in" role="alert") {{messageError}}
          .row
            .col-sm-12
              .date-cn
                span(v-if="isTypeApp === 'vario'") #[translate Fecha de la oferta]:&nbsp;
                span(v-else) #[translate Fecha de envío de la oferta]:&nbsp;
                strong {{ date | moment("DD-MM-YYYY")}}

      .container.table-1(:class="{'full-cn': isTypeApp === 'vario'}")
        .row.table-head(v-if="!lead")
          .holder-cn
            h3 #[translate Centro]
          .holder-cn
            h3  #[translate Cliente]
        .row.table-head(v-else)
          .holder-cn
            h3 Lead
          .holder-cn

        .row.table-grid(v-if="!lead")
          .holder-cn(v-if="center")
            p {{center.name}}
            p {{center.address}}
            p ({{center.postal_code}}, {{center.locality}})
          .holder-cn(v-if="client")
            p {{client.name}}
            p {{client.address}}
            p ({{client.postal_code}}, {{client.locality}})
        .row.table-grid(v-else)
          .holder-cn(v-if="lead")
            p {{lead.name}}
            p {{lead.address}}
            p ({{lead.postal_code}}, {{lead.locality}})
          .holder-cn(v-if="lead")

      .container.table-2
        .row.table-head
          .col-sm-4 &nbsp;
          .col-sm-3 &nbsp;
          .col-sm-2.text-right #[translate Precio Unidad]
          .col-sm-1.text-right #[translate Cantidad]
          .col-sm-1.text-right #[translate Descuento]
          .col-sm-1.text-right #[translate total]

        .table-grid
          bk-item(v-for="item in items" :key="item.id" :product="item" :showErrorDiscount="true")
      // Ponemos 'kk' para ocultar el bloque a todos los ecom, así lo podemos activar si lo necesitan.
      .box-payment(v-if="isTypeApp === 'kk'")
        .container
          .row
            .col-sm-7
              img(:src="img.payment")
              translate Payment
            .col-sm-3
              strong #[translate Payment options]:
              em  XXXX
            .col-sm-2
              strong #[translate Discount]
              em -X,XX {{moneda}}

      .box-3
        .container
          .row(v-if="isTypeApp === 'vario'")
            .col-sm-8.obser-cn
              p(v-if="details")
                strong #[translate Notas]:
                small {{details}}
            .col-sm-4
              ul
                li #[translate subtotal]: #[small {{base | format-money}} {{moneda}}]
                li #[translate Discounts]: #[small {{discounts | format-money}} {{moneda}}]
                li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
                li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]
          .row(v-else)
            .col-sm-8.obser-cn
              p(v-if="details")
                strong #[translate Notas]:
                small {{details}}
            .col-sm-4
              ul
                li {{numItems}} #[translate Productos]: #[small {{base | format-money}} {{moneda}}]
                li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
                li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]

      
      .box-4
        .container
          .row
            .col-sm-8.text-right.pull-right
              p(v-if="user && user.can_validate_offers && type !== 'validados'")
                button.btn.btn-cancel(class="!tw-bg-[#E82C2B] !tw-text-white !tw-border-[#E82C2B]" @click="anular()" :class="{'btn-send': enviando}")
                  span(v-if="!enviando") #[translate Anular]
                  span(v-if="enviando") #[translate Anulando]
                button.btn.btn-action(class="!tw-bg-[#F49700] !tw-text-white tw-mx-2" @click="editOffer" :class="{'btn-send': enviando}")
                  span(v-if="!enviando") #[translate Editar]
                  span(v-if="enviando") #[translate Editando]
                button.btn.btn-action(class="!tw-bg-[#1D9240] !tw-text-white"  @click="validar()" :class="{'btn-send': enviando}")
                  span(v-if="!enviando") #[translate Validar]
                  span(v-if="enviando") #[translate Validando]

              p(v-if="type == 'validados'")
                button.btn.btn-cancel(@click="goBack()") #[translate Volver]
              p &nbsp;

</template>
<script>
import appConfig from '@/config-app.js'

import BkItem from '@/components/ofertas/Item-single.vue'
// importamos servicios
import SrvValidarOfertas from '@/services/validar-ofertas'
import * as types from '@/store/mutation-types'
export default {
  name: 'OfertasValidarSingle',
  props: ['id', 'type'],
  components: { BkItem },
  data () {
    return {
      messageError: null,
      img: {
        alerta: require('@/assets/img/icos/alerta-01.svg'),
        payment: require(`@/assets/img/icos/payment.svg`),
        shipping: require(`@/assets/img/icos/shipping.svg`),
        shippingFree: require(`@/assets/img/icos/shipping_free.svg`),
        shippingFreeNo: require(`@/assets/img/icos/shipping_free_no.svg`),
        recurrentes: require('@/assets/img/pedidoRecurrente.svg')
      },
      messageShow: null,
      stage: null,
      number: null,
      date: null,
      base: null,
      discounts: null,
      tax: null,
      total: null,
      moneda: null,
      details: null,
      lead: null,
      center: null,
      client: null,
      items: [],
      enviando: false
    }
  },
  computed: {
    numItems () {
      if (this.items) {
        return this.items.length
      }
      return 0
    },
    user () {
      return this.$store.getters.user
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
  },
  created () {
    this.getOferta()
    console.log(`id: ${this.id}, type: ${this.type}`)
  },
  methods: {
    async editOffer () {
      if (!this.enviando) {
        this.enviando = true
        const offerEditing = {id: this.id, number: this.number}
        window.localStorage.setItem('EDIT_OFFER_PENDING', JSON.stringify(offerEditing))        
        this.$store.commit(types.EDIT_OFFER_PENDING, offerEditing)
        await this.$store.dispatch('deleteCart')
        if (this.lead) {
          // console.log('--------', item)
          const email = this.lead.email
          if (email) {
            // Enviamos a Store Lead los datos del Lead
            this.$store.dispatch('setLead', this.lead)
            // Lo pasamos a su State
            this.$store.commit('SET_LEAD')
            this.$router.push({'name': 'leadOferta'})
          }
        } else {
          this.$store.dispatch('setClient', this.client)
          this.$store.commit('SET_CLIENT')
          if (this.center) {
            this.$store.dispatch('setCenter', this.center)
            this.$store.commit('SET_CENTER')
          }
        }
        await Promise.all(this.items.map( async (item, index) => {
          let obj = {
            'client': this.client?.id ? this.client.id : '',
            'product': item.product.id,
            'quantity': item.quantity,
            'discount': item.discount,
            'description': '',
            'billable': false
          }
          if (this.lead) {
            obj.lead = this.lead.id
          }
          await this.$store.dispatch('addCart', {idProduct: item.product.id, quantity: item.quantity, product: obj, 'hide': true})
        }))
        if (this.lead) {
          this.$router.push({name: 'leadOfertaCartList'})
        } else {
          this.$router.push({name: 'cartList'})
        }
        this.enviando = false

      }
    },
    getOferta () {
      SrvValidarOfertas.getOferta(this.id, this.type)
        .then(res => {
          // console.log('getOferta: ', res.data)
          if (res.status === 200) {
            const data = res.data
            this.number = data.number
            this.date = data.offer_datetime
            this.base = res.data.base
            this.discounts = res.data.discounts
            this.tax = res.data.tax_total
            this.total = res.data.total
            this.moneda = data.currency_symbol
            this.items = res.data.entries
            this.details = data.details
            this.client = res.data.client
            this.center = res.data.center
            this.lead = res.data.lead
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            window.scrollTo(0, 0)
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          window.scrollTo(0, 0)
        })
    },
    anular () {
      this.messageShow = null

      if (!this.enviando) {
        this.enviando = true
        SrvValidarOfertas.validarAnular(this.id, 'True')
          .then(res => {
            if (res.status === 200) {
              // console.log(`Anular: `, res.data)
              this.messageShow = true
              this.stage = 'offeranulated'
              window.scrollTo(0, 0)
            } else {
              this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            }
            // actualizamos el valor de los pedidos pendientes.
            this.$store.dispatch('updateValidableOrders')
            this.enviando = false
          }, (error) => {
            // console.log('----->' + error)
            if (error.data.code === '105') {
              this.messageShow = true
              this.stage = 'offermodeoffline'
              this.messageError = error.data.message
            } else {
              this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            }
            this.enviando = false
          })
      }
    },
    validar () {
      this.messageShow = null
      if (!this.enviando) {
        this.enviando = true
        SrvValidarOfertas.validarAnular(this.id, 'false')
          .then(res => {
            if (res.status === 200) {
              // console.log(`Anular: `, res.data)
              this.messageShow = true
              this.stage = 'offervalidated'
              window.scrollTo(0, 0)
            } else {
              this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            }
            // actualizamos el valor de los pedidos pendientes.
            this.$store.dispatch('updateValidableOffers')
            this.enviando = false
          }, (error) => {
            // console.log('----->' + error)
            if (error.data.code === '100') {
              this.messageShow = true
              this.stage = 'pendingvalidation'
              window.scrollTo(0, 0)
            } else if (error.data.code === '102') {
              this.messageError = error.data.message
            } else if (error.data.code === '105') {
              this.messageShow = true
              this.stage = 'offermodeoffline'
              this.messageError = error.data.message
            } else {
              this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            }
            window.scrollTo(0, 0)
            this.enviando = false
          })
      }
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .ofertas-validar-single{
    padding:0 0 200px;
  }

  .box-search{
    padding: 14px 0;
    background-color: #EFEFEF;

    span{
      margin-left: 18px;
      font-weight: $medium;
    }

    strong{
      margin-left: 6px;
      font-weight: $medium;
    }

  }

  .alert{
    margin-top: 40px;
  }

  .box-1{
    padding: 26px 0 20px;
    font-size: 16px;
    small{
      font-size: 16px;
    }

    .date-cn{
      span{
        font-weight: $bold;
      }
    }

    strong{
      font-weight: $regular;
    }
  }

  .table-1{
    &.full-cn{
      .table-head, .table-grid{
        .holder-cn{
          width: 100%;
        }
      }
    }
    
    .table-head{
      display: flex;
      justify-content: space-between;
      margin: 6px -15px 18px;

      .holder-cn{
        width: 48%;

        &:nth-child(3){
          h3 {
            font-weight: $medium;
          }
        }
      }

      h3{
        border-bottom: 1px solid #1C2C40;
        font-size: 14px;
        font-weight: $regular;
        padding-bottom: 6px;
      }

      p{
        margin: 0;
      }
    }

    .table-grid{
      display: flex;
      justify-content: space-between;
      margin: 6px -15px 18px;
      font-size: 14px;
      color:#4D4F5C;

      .holder-cn{
        width: 48%;
        padding: 20px 18px;
        background-color: #F1F4F7;
      }

      strong{
        min-width: 130px;
        display: inline-block;
        font-weight: $regular;
      }
    }
  }

  .table-2{
    .table-head{
      margin: 6px 0;
      & > *{
        padding-bottom: 6px;
        border-bottom: 1px solid #979797;
        font-size: 14px;

        &:first-child{
          font-weight: $medium;
        }
      }

      .precio-und{
        padding-left: 0;
        padding-right: 0;
      }

      p{
        margin: 0;
      }
    }
  }

  .box-3{
    margin:24px 0 30px;

    .row{
      border-bottom: 2px solid #17296E;
      padding-bottom:16px;
      margin: 0;
    }

    ul{
      padding-left: 0;
      list-style: none;
    }

    li{
      color: #5b5d5e;
      font-size: 16px;
      margin-bottom: 14px;

      small{
        font-size: 16px;
        float: right;
      }

      &:last-child{
        font-size: 18px;
        font-weight: $medium;
      }
    }

    .obser-cn{
      strong{
        font-weight: $regular;
        color: #494f58;
        font-size: 14px;
        vertical-align: top;
        margin-right: 10px;
      }

      small{
        display: inline-block;
        font-size: 14px;
        width:70%;
        height: 80px;
      }
    }
  }

  .Select-bk{
    width: 240px!important;

    .dropdown-toggle{
      height: 42px!important;
      line-height: 42px!important;

      i{
        line-height: 40px!important;
      }
    }
  }

  .box-payment{
    .row{
      margin: -1px 0 0;
      padding: 30px 0;
      border-top: 2px solid #17296E;
      border-bottom: 2px solid #17296E;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > * {
        font-size: 14px;
        color: #1C2C40;

        &:first-child{
          padding-left:0;
          font-size: 16px;

          img{
            margin-right: 20px;
          }
        }

        &:last-child{
          text-align: right;
          padding-right: 0;

          em{
            background-color: #E2E2E2;
            min-width: 70px;
            height: 34px;
            line-height: 34px;
            text-align: right;
            display: inline-block;
            @include roundAll(2);
            padding: 0 8px;
            vertical-align: middle;
          }
        }

        strong{
          font-weight: $regular;
          margin-right: 20px;
        }

        em{
          font-style: normal;
          color: #646464;
        }
      }
    }
  }

  .box-4{
    p{
      font-size: 18px;
      color: #494f58;
    }

    .btn{
      font-size: 16px;
      font-weight: $regular;
      margin-left: 20px;
    }
  }

  .info-pedido-ok{
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e6f7d4;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 700px;

  &.error{
    background-color: #FFF1D3;
  }
  & > div{
    padding: 40px;
  }

  p{
    text-align: center;
    font-size: 22px;
    color: #5c6783;
    font-weight: $light;
    margin-bottom: 30px;
  }

  i{
    color: #ace174;
    border: 3px solid #ace174;
    @include roundAll100(50%);
    width: 70px;
    height: 70px;
    line-height: 64px;
    font-size: 40px;

    &.orange-ico{
      color: #FEC731;
      border-color: #FEC731;
    }
  }

  a{
    display: inline-block;
    margin-left: 6px;
  }

  strong{
    color: #2b569b;
    font-size: 30px;
    font-weight: $light;
  }
}
</style>
